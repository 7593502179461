import { useEffect } from 'react';
import { useModal } from '@/providers/ModalProvider';
import { useSyncContext } from '@/providers/SyncProvider';

export const useKeyboardCommands = () => {
  const { toggleModal } = useModal();
  const { loading, handleSync } = useSyncContext();

  useEffect(() => {
    const down = async (e: KeyboardEvent) => {
      if (e.key.toLocaleLowerCase() === 's' && (e.metaKey || e.ctrlKey) && !loading) {
        e.preventDefault();
        await handleSync();
      }
      if (e.key.toLocaleLowerCase() === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        toggleModal('search');
      }
    };

    document.addEventListener('keydown', down);
    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [handleSync, loading, toggleModal]);
};

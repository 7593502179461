import { Button } from '@/components/ui/button';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const AddPage = () => {
  const { addPage } = useLocalDBContext();
  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const pageId = await addPage();
    navigate(`/page/${String(pageId)}`);
  };
  return (
    <div>
      <Button
        onClick={handleSubmit}
        variant="ghost"
        size="icon"
        className="p-0 m-0 h-8 w-8 outline-none"
      >
        <Plus className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default AddPage;

import { Cloud, Settings, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useGoogleDrive } from '@/providers/GoogleDriveProvider';

const AppSettings = ({ onClose }: { onClose: () => void }) => {
  const { lastSyncedAt } = useGoogleDrive();
  const { isConnected, user, isSyncing, handleGoogleDriveConnect, syncWithGoogleDrive } =
    useGoogleDrive();

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">
          <div className="flex items-center justify-center space-x-2 text-2xl">
            <Settings className="h-6 w-6" />
            <span className="pb-1">Settings</span>
          </div>
        </CardTitle>
        <Button onClick={onClose} variant="ghost" className="h-4 w-4 p-0">
          <X className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center justify-between my-5">
            <div className="space-y-1">
              <p className="font-medium">Google Drive Backup</p>
              <p className="text-sm text-gray-500">
                Backup/Restore your notes from your Google Drive{' '}
              </p>
              <p className="text-sm text-gray-500">{user?.email}</p>
              {lastSyncedAt && (
                <p className="text-xs text-gray-400">
                  Last synced: {new Date(lastSyncedAt).toLocaleString()}
                </p>
              )}
            </div>
            {isConnected ? (
              <Button
                onClick={syncWithGoogleDrive}
                disabled={isSyncing}
                variant="outline"
                className="text-green-600 border-green-600"
              >
                <Cloud className="mr-2 h-4 w-4" />
                {isSyncing ? 'Syncing...' : 'Sync Now'}
              </Button>
            ) : (
              <Button
                variant={'outline'}
                onClick={handleGoogleDriveConnect}
                className="text-orange-600 hover:text-orange-600 border-orange-600"
              >
                Connect
              </Button>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AppSettings;

import React, { useEffect, useState } from 'react';
import { MoreHorizontal } from 'lucide-react';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { PageMetadata } from '@/types/page.types';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useMediaQuery } from 'usehooks-ts';
import { SearchBox } from '../Search';
import { CustomModal } from '../../ui/modal';
import ListContainer from './ListContainer';

const FullList = ({ pagesMetadata }: { pagesMetadata: PageMetadata[] }) => {
  const { searchPages } = useLocalDBContext();

  const isMobile = useMediaQuery('(max-width: 768px)');
  const [searchResults, setSearchResults] = useState<PageMetadata[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        searchPages(searchQuery)
          .then(results => {
            setSearchResults(results);
          })
          .catch(() => {
            setSearchResults([]);
          });
      } else {
        setSearchResults(pagesMetadata);
      }
    }, 100);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchQuery, searchPages, pagesMetadata]);

  const Content = ({ onSelect }: { onSelect?: () => void }) => (
    <div className="space-y-4">
      {!isMobile && <SearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
      <ListContainer pagesMetadata={searchResults} onSelect={onSelect} />
    </div>
  );

  if (isMobile) {
    return (
      <MoreButton
        content={
          <Content
            onSelect={() => {
              setOpen(false);
            }}
          />
        }
      />
    );
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="w-full">
        <div className="flex space-x-2 pl-3 p-2 hover:bg-muted w-full cursor-pointer rounded">
          <MoreHorizontal className="h4 w-4" />
          <span className="font-semibold text-sm">More</span>
        </div>
      </PopoverTrigger>
      <PopoverContent
        side={'right'}
        className="w-72 p-0 bg-secondary ml-1.5 h-lvh overflow-y-auto z-[999!important]"
      >
        <Content
          onSelect={() => {
            setOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export default FullList;

const MoreButton: React.FC<{
  content: React.ReactNode;
}> = ({ content }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        className="w-full flex items-center justify-between px-2"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className="flex items-center justify-center bg-background w-full mt-4 p-2 rounded-md ">
          <span className="font-semibold text-sm">More</span>
        </div>
      </button>
      {isModalOpen && (
        <CustomModal
          isOpen={isModalOpen}
          centered={true}
          maxHeight={'calc(100vh - 220px)'}
          onClose={() => {
            setIsModalOpen(false);
          }}
        >
          {React.cloneElement(content as React.ReactElement, {
            onSelect: () => {
              setIsModalOpen(false);
            },
          })}
        </CustomModal>
      )}
    </>
  );
};

const CONTENT = [
  {
    id: 'a9e12647-71a5-48ed-8ded-422e86afdf59',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Andika ',
        styles: {
          bold: true,
        },
      },
      {
        type: 'text',
        text: 'means "',
        styles: {},
      },
      {
        type: 'text',
        text: 'Write',
        styles: {
          bold: true,
        },
      },
      {
        type: 'text',
        text: '" in Swahili.',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'cb49dbc9-7c27-437b-abd9-8092f8e533b2',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Andika Online is a ',
        styles: {},
      },
      {
        type: 'text',
        text: 'minimalist note-taking app',
        styles: {
          underline: true,
        },
      },
      {
        type: 'text',
        text: ' that helps you capture your thoughts on the fly.\nWith ',
        styles: {},
      },
      {
        type: 'text',
        text: 'blazing-fast browser storage,',
        styles: {
          textColor: 'red',
        },
      },
      {
        type: 'text',
        text: ' your notes are instantly saved and always available, even offline.',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'f109b9af-f704-4df2-a30e-29e9909a2a6d',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [],
    children: [],
  },
  {
    id: '90a5d436-a100-417c-bf97-3e255790a0bf',
    type: 'heading',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
      level: 3,
    },
    content: [
      {
        type: 'text',
        text: 'What makes this special?',
        styles: {
          bold: true,
        },
      },
    ],
    children: [],
  },
  {
    id: '3da7a1dd-126f-4ba8-a300-a1d992a41cc4',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Notes are saved locally',
        styles: {
          textColor: 'yellow',
        },
      },
      {
        type: 'text',
        text: ' on your browser for fast access. ',
        styles: {},
      },
      {
        type: 'text',
        text: 'No more loading',
        styles: {
          underline: true,
        },
      },
      {
        type: 'text',
        text: '. ',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '339cf0a2-5511-4db2-b805-486efea01687',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Optional sign-in',
        styles: {
          textColor: 'yellow',
        },
      },
      {
        type: 'text',
        text: ' ',
        styles: {
          textColor: 'orange',
        },
      },
      {
        type: 'text',
        text: 'to ',
        styles: {},
      },
      {
        type: 'text',
        text: 'backup & sync your notes',
        styles: {
          underline: true,
        },
      },
      {
        type: 'text',
        text: ' with other devices.',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '1a319791-dcd3-48a2-afbb-8b077b829afc',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Notes are encrypted',
        styles: {
          textColor: 'yellow',
        },
      },
      {
        type: 'text',
        text: ' in-transit and at rest. ',
        styles: {},
      },
      {
        type: 'text',
        text: '100%',
        styles: {
          bold: true,
          underline: true,
        },
      },
      {
        type: 'text',
        text: ' for your eyes only',
        styles: {
          underline: true,
        },
      },
      {
        type: 'text',
        text: '. ',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'c96b0740-67bc-4ffe-814a-752dace62c57',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Backup ',
        styles: {
          textColor: 'yellow',
        },
      },
      {
        type: 'text',
        text: 'your notes ',
        styles: {},
      },
      {
        type: 'text',
        text: 'to your own Google Drive',
        styles: {
          underline: true,
        },
      },
      {
        type: 'text',
        text: ' account.',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'a066eb63-df4f-4f4d-a463-653de292450e',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Share notes',
        styles: {
          textColor: 'yellow',
        },
      },
      {
        type: 'text',
        text: ' with others using public links.',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '960679ba-bee9-4a12-832e-f17855e8dff7',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: '\nStart writing now and let your ideas flow freely!',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'f7bb8b9c-155e-4444-aefa-f147c55794b6',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [],
    children: [],
  },
  {
    id: '6b8afa6f-181b-464b-acea-fb3901dff5a7',
    type: 'heading',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
      level: 3,
    },
    content: [
      {
        type: 'text',
        text: 'Why use this?',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '10fd8925-d37c-479e-af33-982a3e774019',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: '✅ Fast',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'd8ad0458-52c0-4ad9-ab99-0c4f0f672774',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: '✅ Free',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '82e8a409-2c30-4259-b54a-b3d0b2f07174',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: '✅ Simple',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '3b5e9a40-5aad-4e1a-8d54-9b985c2dd737',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [],
    children: [],
  },
  {
    id: '237a5918-5fe6-45a7-9f7f-86f5a4c5b3eb',
    type: 'heading',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
      level: 3,
    },
    content: [
      {
        type: 'text',
        text: 'Some use cases',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '947d8b49-6bc1-4815-8b84-c2e6379c5e3c',
    type: 'bulletListItem',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Capturing those fleeting ideas',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'eb170a6c-1ab5-42f0-a70d-d00e6f53a5bd',
    type: 'bulletListItem',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Drafting emails responses etc.',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: 'e675848b-2ada-498b-98bd-47854c0b5398',
    type: 'bulletListItem',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Managing random to-do lists',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '7072ccf0-eec2-4f30-b880-6ccc0d56c364',
    type: 'bulletListItem',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Capturing meeting notes',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '9709143b-ed26-4dfe-9edf-060aaf5eb58e',
    type: 'bulletListItem',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Drafting blogs',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '6332a5a6-fe5d-4057-8661-c33c428b883f',
    type: 'bulletListItem',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: 'Saving blogs',
        styles: {},
      },
    ],
    children: [],
  },
  {
    id: '325b996e-f3d2-4aa3-85a7-82caeca6e59b',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [],
    children: [],
  },
  {
    id: '31507978-81e3-4b65-ad31-5987d5ff0bc2',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [],
    children: [],
  },
  {
    id: 'a1120753-6eab-4363-9e3b-9e170982d8ac',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [],
    children: [],
  },
];

export const WHAT_IS_THIS_PAGE = {
  id: 'about',
  orderNumber: 2,
  title: "What's this? 🤔",
  content: CONTENT,
};

import React, { ReactNode } from 'react';
import { Modal } from 'antd';

export interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  width?: number;
  height?: number | string;
  maxHeight?: string;
  centered?: boolean;
  style?: React.CSSProperties;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  children,
  width,
  height,
  maxHeight = 'calc(100vh - 100px)',
  centered = true,
  style,
}) => {
  const modalContent = isOpen ? (
    <div
      className="bg-secondary text-secondary-foreground rounded-lg"
      style={{
        height: height,
        maxHeight: maxHeight,
        overflowY: 'auto',
        ...style,
      }}
    >
      {children}
    </div>
  ) : null;

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      closable={false}
      width={width || 520}
      centered={centered}
      style={style}
      destroyOnClose={true} // This ensures Modal is fully destroyed on close
    >
      {modalContent}
    </Modal>
  );
};

import { RemoteDBService } from '@/services/database/RemoteDBService';
import { Page } from '@/types/page.types';
import { useState, useCallback, useMemo } from 'react';

function useBackend() {
  const [page, setPage] = useState<Page | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const backendService = useMemo(() => new RemoteDBService(), []);

  const getRecordById = useCallback(
    async (id: string) => {
      setLoading(true);
      setHasError(false);
      try {
        const result = await backendService.getPublicNote(id);
        setPage(result);
        return result;
      } catch (error: unknown) {
        setHasError(true);
        setPage(null);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [backendService]
  );

  return { page, loading, getRecordById, hasError };
}

export default useBackend;

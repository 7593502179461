import { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalDBProvider } from '@/providers/LocalDBProvider';
import { ThemeProvider } from '@/providers/ThemeProvider';
import { ModalProvider } from '@/providers/ModalProvider';
import { GoogleDriveProvider } from '@/providers/GoogleDriveProvider';
import { SyncProvider } from '@/providers/SyncProvider';
import { CustomToaster } from '@/providers/Toaster';
import { TooltipProvider } from '@/components/ui/tooltip';
import RefreshAppPrompt from '@/components/RefreshAppPrompt';

interface ProviderWrapperProps {
  children: ReactNode;
}

type ProviderWithProps = {
  Provider: React.FC<{ children: ReactNode }>;
  props?: Record<string, unknown>;
};

const providers: ProviderWithProps[] = [
  {
    Provider: Router,
    props: {},
  },
  {
    Provider: LocalDBProvider,
    props: {},
  },
  {
    Provider: GoogleDriveProvider,
    props: {},
  },
  {
    Provider: SyncProvider,
    props: {},
  },
  {
    Provider: ThemeProvider,
    props: {
      defaultTheme: 'blue' as const,
      storageKey: 'vite-ui-theme',
    },
  },
  {
    Provider: ModalProvider,
    props: {},
  },
  {
    Provider: TooltipProvider,
    props: {},
  },
];

export const ProviderWrapper: React.FC<ProviderWrapperProps> = ({ children }) => {
  return (
    <>
      {providers.reduceRight(
        (acc, { Provider, props = {} }) => (
          <Provider {...props}>{acc}</Provider>
        ),
        <>
          {children}
          <CustomToaster />
          <RefreshAppPrompt />
        </>
      )}
    </>
  );
};

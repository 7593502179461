import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { SyncEngine } from '@/services/database/SyncEngine';
import { useLocalDBContext } from './LocalDBProvider';
import { AuthService } from '@/services/auth/AuthService';
import { User } from 'firebase/auth';
import localDbService from '@/services/database/LocalDBService';
import { debounce } from 'lodash-es';

interface SyncContextType {
  user: User | null;
  authLoading: boolean;
  loading: boolean;
  error: Error | null;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  handleSync: () => Promise<void>;
  lastSyncedAt: number | null;
}

const SyncContext = createContext<SyncContextType | undefined>(undefined);

interface SyncProviderProps {
  children: ReactNode;
}

export const SyncProvider: React.FC<SyncProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [lastSyncedAt, setLastSyncedAt] = useState<number | null>(null);

  const { loadPages, setIsDirty, refreshCurrentPage, refreshTags } = useLocalDBContext();

  const authService = useMemo(() => new AuthService(), []);
  const syncEngine = useMemo(() => new SyncEngine(), []);

  const getLastSynced = useCallback(() => localDbService.getLastSyncTime(), []);

  const syncCore = useCallback(async () => {
    try {
      setLoading(true);
      const lastSynced = await syncEngine.performSync();
      setIsDirty(false);
      setTimeout(async () => {
        await loadPages();
        await refreshCurrentPage();
        await refreshTags();
        setLastSyncedAt(lastSynced);
      }, 0);
      setError(null);
    } catch (err) {
      console.error({ handleSync: error });
      setError(err instanceof Error ? err : new Error('Sync failed'));
      console.error('Sync failed:', err);
    } finally {
      setLoading(false);
    }
  }, [syncEngine]);

  // Create a debounced version that persists between renders
  const debouncedSyncRef = useRef(
    debounce(async () => {
      await syncCore();
    }, 500)
  );

  const handleSync = useCallback(async () => {
    if (!user) return;
    await debouncedSyncRef.current();
  }, [user]);

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(newUser => {
      setUser(newUser);
      if (newUser) {
        void handleSync();
      } else {
        void getLastSynced().then(lastSyncedAt => {
          setLastSyncedAt(lastSyncedAt);
        });
      }
      setAuthLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [authService, getLastSynced, handleSync]);

  const signIn = useCallback(async () => {
    try {
      setAuthLoading(true);
      setError(null);
      await authService.signIn();
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Sign in failed'));
      throw err;
    }
  }, [authService]);

  const signOut = useCallback(async () => {
    try {
      setError(null);
      await authService.signOut();
      setLastSyncedAt(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Sign out failed'));
      throw err;
    }
  }, [authService]);

  return (
    <SyncContext.Provider
      value={{
        user,
        authLoading,
        loading,
        error,
        signIn,
        signOut,
        handleSync,
        lastSyncedAt,
      }}
    >
      {children}
    </SyncContext.Provider>
  );
};

export const useSyncContext = () => {
  const context = useContext(SyncContext);
  if (context === undefined) {
    throw new Error('useSyncContext must be used within a SyncProvider');
  }
  return context;
};

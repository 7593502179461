import { useCallback, useEffect } from 'react';
import { PencilLine, PencilOff } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { useLocalDBContext } from '@/providers/LocalDBProvider';

export function EditableToggle() {
  const { editable, setEditable } = useLocalDBContext();

  useEffect(() => {
    const cachedCanEdit = localStorage.getItem('editable');
    const canEdit = cachedCanEdit ? localStorage.getItem('editable') === 'true' : true;
    setEditable(canEdit);
  }, [setEditable]);

  const toggleEditable = useCallback(() => {
    setEditable(!editable);
  }, [editable, setEditable]);

  return (
    <Button onClick={toggleEditable} variant={'ghost'} className="p-2 px-3">
      <Tooltip>
        <TooltipTrigger asChild>
          {editable ? (
            <PencilLine className={'h-[1.2rem] w-[1.2rem]'} />
          ) : (
            <PencilOff className="h-[1.2rem] w-[1.2rem]" />
          )}
        </TooltipTrigger>
        <TooltipContent>
          <span>{editable ? 'Disable editing' : 'Enable editing'}</span>
        </TooltipContent>
      </Tooltip>
    </Button>
  );
}

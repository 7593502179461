import { isEqual } from 'lodash-es';
import { subHours } from 'date-fns';
import { INITIAL_PAGES } from '@/services/database/defaultData/defaultNotes';
import { Page } from '@/types/page.types';
import localDB from './LocalDBService';
import { RemoteDBService } from './RemoteDBService';
import { AuthService } from '../auth/AuthService';

type ResolvedChanges = {
  toAddLocally: Page[];
  toAddRemotely: Page[];
  toUpdateLocally: Page[];
  toUpdateRemotely: Page[];
  toDeleteLocally: Page[];
  toDeleteRemotely: Page[];
};
export class SyncEngine {
  private remoteDB: RemoteDBService;
  private authServer: AuthService;

  constructor() {
    this.remoteDB = new RemoteDBService();
    this.authServer = new AuthService();
  }

  signIn() {
    return this.authServer.signIn();
  }

  async performSync() {
    let lastSync: number | null = await localDB.getLastSyncTime();
    lastSync = lastSync ? subHours(new Date(lastSync), 1).getTime() : null;

    const remoteChanges = await this.fetchRemoteChanges(lastSync);

    const localChanges = await this.gatherLocalChanges(lastSync);

    const resolvedChanges = this.resolveConflicts(remoteChanges, localChanges);

    console.log({ lastSync: lastSync, remoteChanges, localChanges, resolvedChanges });

    await this.applyChangesInTransaction(resolvedChanges);

    const lastSynced = Date.now();

    await localDB.updateLastSyncTime(lastSynced);

    return lastSynced;
  }

  private isDefaultNote(page: Page): boolean {
    return INITIAL_PAGES.map(p => p.title).includes(page.title);
  }

  private isOriginalDefaultNote(page: Page): boolean {
    // Check if this note matches exactly with the original default note
    const matchingDefault = INITIAL_PAGES.find(defaultPage => defaultPage.title === page.title);
    if (!matchingDefault) return false;

    return isEqual(page.createdAt, page.updatedAt);
  }

  async fetchRemoteChanges(lastSync: number | null) {
    return await this.remoteDB.getChangesSince(lastSync);
  }

  gatherLocalChanges(lastSync: number | null) {
    return localDB.getNotesSince(lastSync);
  }

  resolveConflicts(remoteChanges: Page[], localChanges: Page[]): ResolvedChanges {
    const resolvedChanges: ResolvedChanges = {
      toAddLocally: [],
      toAddRemotely: [],
      toUpdateLocally: [],
      toUpdateRemotely: [],
      toDeleteLocally: [],
      toDeleteRemotely: [],
    };

    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

    // Create maps for faster lookups
    const remoteMap = new Map(remoteChanges.map(page => [page.id, page]));
    const localMap = new Map(localChanges.map(page => [page.id, page]));

    // Separate default pages from regular notes
    const remoteDefaultPages = remoteChanges.filter(page => this.isDefaultNote(page));
    const localDefaultPages = localChanges.filter(page => this.isDefaultNote(page));

    // Handle default pages separately
    for (const defaultPage of INITIAL_PAGES) {
      const localPages = localDefaultPages
        .filter(p => p.title === defaultPage.title)
        .sort((a, b) => b.updatedAt - a.updatedAt);
      const remotePages = remoteDefaultPages
        .filter(remotePage => remotePage.title === defaultPage.title)
        .sort((a, b) => b.updatedAt - a.updatedAt);

      const localPage = localPages.length > 0 ? localPages[0] : undefined;
      const remotePage = remotePages.length > 0 ? remotePages[0] : undefined;

      const localToDelete = localPage ? localPages.filter(p => p.id !== localPage.id) : [];
      const remoteToDelete = remotePage ? remotePages.filter(p => p.id !== remotePage.id) : [];
      resolvedChanges.toDeleteLocally.push(...localToDelete);
      resolvedChanges.toDeleteRemotely.push(...remoteToDelete);

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (remotePage && localPage) {
        if (localPage.updatedAt > remotePage.updatedAt && !this.isOriginalDefaultNote(localPage)) {
          if (remoteMap.get(localPage.id)) {
            resolvedChanges.toUpdateRemotely.push(localPage);
          } else {
            resolvedChanges.toAddRemotely.push(localPage);
            resolvedChanges.toDeleteRemotely.push(remotePage);
          }
        } else {
          // add the local one as the remote one
          if (localMap.get(remotePage.id)) {
            resolvedChanges.toUpdateLocally.push(remotePage);
          } else {
            resolvedChanges.toAddLocally.push(remotePage);
            resolvedChanges.toDeleteLocally.push(localPage);
          }
        }
      } else if (localPage && !this.isOriginalDefaultNote(localPage)) {
        resolvedChanges.toAddRemotely.push(localPage);
      } else if (remotePage && !this.isOriginalDefaultNote(remotePage)) {
        resolvedChanges.toAddLocally.push(remotePage);
      }
    }

    // Regular notes (excluding default pages)
    const remoteRegularPages = remoteChanges.filter(page => !this.isDefaultNote(page));
    const localRegularPages = localChanges.filter(page => !this.isDefaultNote(page));

    // Process remote changes
    for (const remotePage of remoteRegularPages) {
      const localPage = localMap.get(remotePage.id);

      // Handle deletions first
      if (remotePage.archivedAt && remotePage.archivedAt < oneWeekAgo) {
        resolvedChanges.toDeleteLocally.push(remotePage);
        continue;
      }

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (!localPage) {
        // New remote page
        resolvedChanges.toAddLocally.push(remotePage);
      } else {
        // Conflict resolution based on updatedAt timestamp
        if (remotePage.updatedAt > localPage.updatedAt) {
          resolvedChanges.toUpdateLocally.push(remotePage);
        }
      }
    }

    // Process local changes
    for (const localPage of localRegularPages) {
      const remotePage = remoteMap.get(localPage.id);

      // Handle deletions first
      if (localPage.archivedAt && localPage.archivedAt < oneWeekAgo) {
        resolvedChanges.toDeleteRemotely.push(localPage);
        resolvedChanges.toDeleteLocally.push(localPage);
        continue;
      }

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (!remotePage) {
        // New local page
        resolvedChanges.toAddRemotely.push(localPage);
      } else {
        // Conflict resolution based on updatedAt timestamp
        if (localPage.updatedAt > remotePage.updatedAt) {
          resolvedChanges.toUpdateRemotely.push(localPage);
        }
      }
    }

    // Deduplicate arrays to prevent any potential double-processing
    const deduplicate = (array: Page[]) =>
      Array.from(new Map(array.map(item => [item.id, item])).values());

    return {
      toAddLocally: deduplicate(resolvedChanges.toAddLocally),
      toAddRemotely: deduplicate(resolvedChanges.toAddRemotely),
      toUpdateLocally: deduplicate(resolvedChanges.toUpdateLocally),
      toUpdateRemotely: deduplicate(resolvedChanges.toUpdateRemotely),
      toDeleteLocally: deduplicate(resolvedChanges.toDeleteLocally),
      toDeleteRemotely: deduplicate(resolvedChanges.toDeleteRemotely),
    };
  }

  async applyChangesInTransaction(resolvedChanges: ResolvedChanges) {
    const {
      toAddLocally,
      toUpdateLocally,
      toDeleteLocally,
      toAddRemotely,
      toUpdateRemotely,
      toDeleteRemotely,
    } = resolvedChanges;

    await localDB.bulkUpsertPages([...toAddLocally, ...toUpdateLocally]);
    await localDB.bulkDeletePages(toDeleteLocally);

    await this.remoteDB.bulkAddNotes(toAddRemotely);
    await this.remoteDB.bulkUpdateNotes(toUpdateRemotely);
    await this.remoteDB.bulkDeleteNotes(toDeleteRemotely);
  }
}

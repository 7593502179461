import Dexie, { Table, Transaction } from 'dexie';
import { v4 as uuidv4 } from 'uuid';
import { Page, SyncMetadata } from '@/types/page.types';

class PageDatabase extends Dexie {
  pages!: Table<Page, string>;
  syncMetadata!: Table<SyncMetadata, string>;

  constructor() {
    super('PageDatabase');

    this.version(2).stores({
      pages: 'id, title, content, orderNumber, createdAt, updatedAt, archivedAt',
    });

    this.version(3)
      .stores({
        pages: 'id, title, content, orderNumber, createdAt, updatedAt, archivedAt',
        syncMetadata: 'id, lastSyncedAt, deviceId',
      })
      .upgrade(async (trans: Transaction) => {
        // Initialize sync metadata during upgrade
        await trans.table('syncMetadata').add({
          id: 'global',
          lastSyncedAt: 0,
          deviceId: uuidv4(),
        });
      });

    this.version(4)
      .stores({
        pages: 'id, title, content, orderNumber, createdAt, updatedAt, archivedAt, *tags',
        syncMetadata: 'id, lastSyncedAt, deviceId',
      })
      .upgrade(async (trans: Transaction) => {
        // Add tags array to existing pages
        const pages = await trans.table('pages').toArray();
        await Promise.all(
          pages.map((page: Page) =>
            trans.table('pages').update(page.id, {
              ...page,
              tags: [], // Initialize empty tags array for existing pages
            })
          )
        );
      });

    // Hook for automatically generating IDs for new pages
    this.pages.hook('creating', (_, obj) => {
      if (!obj.id) obj.id = uuidv4();
      if (!obj.tags) obj.tags = []; // Ensure tags array exists
    });
  }
}

export const db = new PageDatabase();

import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { useSyncContext } from '@/providers/SyncProvider';
import { PageMetadata } from '@/types/page.types';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import ListItem from './ListItem';

const ListContainer = ({
  pagesMetadata,
  maxCount,
  onSelect,
}: {
  pagesMetadata: PageMetadata[];
  maxCount?: number;
  onSelect?: () => void;
}) => {
  const { currentPage, archivePage, reorderPages } = useLocalDBContext();
  const { lastSyncedAt } = useSyncContext();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const [reorderedItem] = pagesMetadata.splice(result.source.index, 1);
    pagesMetadata.splice(result.destination.index, 0, reorderedItem);

    void reorderPages([...pagesMetadata.map(item => item.id)]);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} className="w-full space-y-1">
            {pagesMetadata.slice(0, maxCount || pagesMetadata.length).map((metadata, index) => (
              <Draggable key={metadata.id} draggableId={metadata.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                  >
                    <ListItem
                      id={metadata.id}
                      isSelected={currentPage?.id === metadata.id}
                      title={metadata.title}
                      isUnsynced={!!lastSyncedAt && lastSyncedAt < metadata.updatedAt}
                      archivePage={archivePage}
                      onSelect={onSelect}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ListContainer;

import React, { createContext, useState, useContext, ReactNode } from 'react';
import { SearchModal } from '@/components/Sidebar/Search';

type ModalType = 'search' | null;

interface ModalContextType {
  toggleModal: (modalType: ModalType) => void;
  openedModal: ModalType;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [openedModal, toggleModal] = useState<ModalType>(null);

  const closeModal = () => {
    toggleModal(null);
  };

  return (
    <ModalContext.Provider value={{ openedModal, toggleModal }}>
      {children}
      <SearchModal isOpen={openedModal === 'search'} onClose={closeModal} />
    </ModalContext.Provider>
  );
};

import { memo, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import Logo from '@/components/Logo';
import Editor from '@/components/Editor';
import PageTitle from '@/components/PageTitle';
import { ThemeToggle } from '@/components/Menu/ThemeToggle';
import { useParams } from 'react-router-dom';
import useBackend from '@/hooks/useBackend';
import Spinner from '@/components/ui/spinner';
import { Page } from '@/types/page.types';

const SharedPage = () => {
  const { pageId } = useParams();
  const { page, loading, getRecordById, hasError } = useBackend();

  useEffect(() => {
    if (!pageId) return;
    void getRecordById(pageId);
  }, [pageId]);

  if (loading) {
    return (
      <>
        <Menu />
        <div className="h-[calc(100vh-400px)] flex items-center justify-center">
          <Spinner size={'icon'} />
        </div>
      </>
    );
  }

  if (!page || hasError) {
    return (
      <>
        <Menu />

        <div className="h-[calc(100vh-400px)] flex items-center justify-center">
          <div className="flex-col space-y-2 text-center">
            <div className="text-3xl text-muted-foreground">¯/_(ツ)_/¯</div>
            <div className="text-lg text-muted-foreground pt-4">
              Sorry, couldn't find what you're looking for.
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="h-screen overflow-y-auto">
      <Menu page={page} />
      <div className="mx-auto md:max-w-3xl lg:max-w-4xl">
        <PageTitle title={page.title} editable={false} onChange={() => {}} />
        <Editor enableEditing={false} initialContent={page.content} onChange={() => {}} />
      </div>
    </div>
  );
};

export default memo(SharedPage);

const Menu = ({ page }: { page?: Page }) => {
  const [showTitle, setShowTitle] = useState(false);
  const lastScrollTop = useRef(0);
  const menuRef = useRef<HTMLElement | null>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const handleScroll = () => {
      if (!menuRef.current) return;

      const scrollContainer = menuRef.current.closest('.overflow-y-auto');
      if (!scrollContainer) return;

      const scrollTop = scrollContainer.scrollTop;
      if (scrollTop > lastScrollTop.current && scrollTop > 60) {
        setShowTitle(true);
      } else if (scrollTop <= 60) {
        setShowTitle(false);
      }
      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    };

    const scrollContainer = menuRef.current?.closest('.overflow-y-auto');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <nav ref={menuRef} className="sticky z-50 top-0 w-full bg-background">
      <div className="flex items-center justify-between p-2 md:px-0 w-full">
        {(!isMobile || !showTitle) && <Logo />}
        {showTitle && page && (
          <div className="truncate text-md font-medium transition-opacity duration-300 ease-in-out md:max-w-3xl lg:max-w-4xl flex-1 px-2 md:p-0">
            {page.title} {page.archivedAt ? '(Archived)' : ''}
          </div>
        )}
        <ThemeToggle />
      </div>
    </nav>
  );
};

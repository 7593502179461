import { Page } from '@/types/page.types';
import { auth } from '../auth/firebase';
import { supabase } from '../utils/supabase';
import { formatISO, getTime } from 'date-fns';
import { decryptData, encryptData } from '@/services/utils/encryptionUtils';

export interface Note {
  id: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string | null;
  title: string;
  content: string;
  orderNumber: number | null;
  userId: string;
  isPublic: boolean;
  tags: unknown; // JSONB from Supabase comes as unknown
}

export class RemoteDBService {
  private supabase = supabase;
  private readonly maxRetries = 3;
  private readonly retryDelay = 1000;
  private userId: string | null = null;

  constructor() {
    auth.onAuthStateChanged(user => {
      this.userId = user?.uid || null;
    });
  }

  private ensureAuthenticated() {
    if (!this.userId) {
      throw new Error('User must be authenticated to perform this operation');
    }
    return this.userId;
  }

  private async executeBatchWithRetry(
    operation: () => Promise<void>,
    retryCount = 0
  ): Promise<void> {
    try {
      await operation();
    } catch (error) {
      console.error(`Operation failed (attempt ${String(retryCount + 1)}):`, error);
      if (retryCount < this.maxRetries) {
        await new Promise(resolve => setTimeout(resolve, this.retryDelay * (retryCount + 1)));
        return this.executeBatchWithRetry(operation, retryCount + 1);
      }
      throw error;
    }
  }

  private async processBatchOperations(
    notes: Page[],
    operation: (notes: Page[]) => Promise<void>
  ): Promise<void> {
    const BATCH_SIZE = 1000; // Supabase allows larger batches than Firestore

    const chunks = Array.from({ length: Math.ceil(notes.length / BATCH_SIZE) }, (_, i) =>
      notes.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE)
    );

    for (const chunk of chunks) {
      await this.executeBatchWithRetry(async () => {
        await operation(chunk);
      });
    }
  }

  async bulkAddNotes(pages: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!pages.length) return;

    await this.processBatchOperations(pages, async chunk => {
      const notes = await Promise.all(
        chunk.map(async page => ({
          ...(await this.convertToNote(page)),
          id: page.id,
          userId: userId,
        }))
      );

      const { error } = await this.supabase.from('Note').upsert(notes, {
        onConflict: 'id',
        ignoreDuplicates: false,
      });

      if (error) throw error;
    });
  }

  async bulkUpdateNotes(pages: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!pages.length) return;

    await this.processBatchOperations(pages, async chunk => {
      // Supabase doesn't support bulk updates in a single query
      // We need to handle each update individually
      await Promise.all(
        chunk.map(async page => {
          const { error } = await this.supabase
            .from('Note')
            .update({
              ...(await this.convertToNote(page)),
            })
            .eq('id', page.id)
            .eq('userId', userId);

          if (error) throw error;
        })
      );
    });
  }

  async bulkDeleteNotes(notes: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!notes.length) return;

    await this.processBatchOperations(notes, async chunk => {
      const noteIds = chunk.map(note => note.id);
      const { error } = await this.supabase
        .from('Note')
        .delete()
        .in('id', noteIds)
        .eq('userId', userId);

      if (error) throw error;
    });
  }

  async getChangesSince(lastSyncTime: number | null): Promise<Page[]> {
    const userId = this.ensureAuthenticated();

    try {
      let query = this.supabase.from('Note').select('*').eq('userId', userId);

      if (lastSyncTime) {
        query = query.gt('updatedAt', new Date(lastSyncTime).toISOString());
      }

      const { data, error } = await query;

      if (error) throw error;

      return await Promise.all((data as Note[]).map(n => this.convertToPage(n)));
    } catch (error) {
      console.error('Error fetching changes:', error);
      throw error;
    }
  }

  async getPublicNote(id: string): Promise<Page | null> {
    try {
      const query = this.supabase.from('Note').select('*').eq('id', id).eq('isPublic', true);

      const { data, error } = await query;

      if (error) throw error;

      return data.length ? await (data as Note[]).map(n => this.convertToPage(n))[0] : null;
    } catch (error) {
      console.error('Error fetching changes:', error);
      throw error;
    }
  }

  // Convert from Supabase Note to your Page type
  private async convertToPage(note: Note): Promise<Page> {
    return {
      id: note.id,
      title: await decryptData(note.title),
      content: await decryptData(note.content),
      createdAt: getTruncatedTime(note.createdAt),
      updatedAt: note.updatedAt ? getTruncatedTime(note.updatedAt) : Date.now(),
      archivedAt: note.archivedAt ? getTruncatedTime(note.archivedAt) : 0,
      orderNumber: note.orderNumber || 0,
      isPublic: note.isPublic || false,
      tags: this.parseJSONBTags(note),
    };
  }

  // Convert from your Page type to Supabase Note
  private async convertToNote(page: Page): Promise<Omit<Note, 'id' | 'user_id'>> {
    const normalizedTags = this.normalizeTags(page.tags || []);
    return {
      userId: this.userId || '',
      createdAt: formatISO(page.createdAt),
      updatedAt: formatISO(page.updatedAt),
      archivedAt: page.archivedAt ? formatISO(page.archivedAt) : null,
      title: await encryptData(page.title),
      content: await encryptData(page.content),
      orderNumber: page.orderNumber,
      isPublic: page.isPublic || false,
      tags: JSON.stringify(normalizedTags),
    };
  }

  private normalizeTags(tags: string[]): string[] {
    if (!Array.isArray(tags)) return [];

    return Array.from(
      new Set(
        tags
          .map(tag => tag.trim().toLowerCase()) // Normalize
          .filter(
            (
              tag
            ): tag is string => typeof tag === 'string' && tag.length > 0 && tag.length <= 100 // Type guard to ensure non-null
          )
      )
    ).slice(0, 50); // Limit to 50 tags
  }

  private parseJSONBTags(note: Note): string[] {
    let parsedTags: string[] = [];
    try {
      const tagsData =
        typeof note.tags === 'string'
          ? (JSON.parse(note.tags) as string[])
          : (note.tags as string[]);
      parsedTags = Array.isArray(tagsData) ? tagsData : [];
    } catch (e) {
      console.error('Error parsing tags:', e);
      parsedTags = [];
    }
    return parsedTags;
  }
}

const getTruncatedTime = (isoDate: string) => ((getTime(new Date(isoDate)) / 1000) | 0) * 1000;

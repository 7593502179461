import { v4 as uuidv4 } from 'uuid';
import { subWeeks } from 'date-fns';
import { OrderOption, Page, PageMetadata } from '@/types/page.types';
import { db } from './database';
import { INITIAL_PAGES } from '@/services/database/defaultData/defaultNotes';

class LocalDBService {
  async getLastSyncTime(): Promise<number> {
    const metadata = await db.syncMetadata.get('global');
    return metadata?.lastSyncedAt || 0;
  }

  get now() {
    return ((Date.now() / 1000) | 0) * 1000;
  }

  async getNotesSince(syncTime: number | null) {
    const notes = await db.pages
      .where('updatedAt')
      .above(syncTime || 0)
      .toArray();

    return notes.map(n => ({
      ...n,
      createdAt: ((n.createdAt / 1000) | 0) * 1000,
      updatedAt: ((n.updatedAt / 1000) | 0) * 1000,
      archivedAt: n.archivedAt ? ((n.archivedAt / 1000) | 0) * 1000 : 0,
    }));
  }

  async updateLastSyncTime(timestamp: number): Promise<void> {
    await db.syncMetadata.put({
      id: 'global',
      lastSyncedAt: timestamp,
      deviceId: (await db.syncMetadata.get('global'))?.deviceId || uuidv4(),
    });
  }

  async getAllPages(orderBy: OrderOption = 'updatedAt'): Promise<Page[]> {
    if (orderBy !== 'orderNumber') {
      return db.pages.where('archivedAt').equals(0).reverse().sortBy(orderBy);
    }
    return db.pages.where('archivedAt').equals(0).sortBy(orderBy);
  }

  async getPagesMetadata(
    orderBy: OrderOption = 'updatedAt',
    filters: string[] = []
  ): Promise<PageMetadata[]> {
    if (!filters.length) {
      return this.getAllPages(orderBy).then(pages =>
        pages.map(({ id, title, orderNumber, updatedAt }) => ({
          id,
          title,
          orderNumber,
          updatedAt,
        }))
      );
    }

    const query = db.pages
      .where('archivedAt')
      .equals(0)
      .and(page => {
        if (!page.tags) return false;
        const filterSet = new Set(filters);
        return page.tags.some(tag => filterSet.has(tag));
      });

    const pages = await (orderBy === 'orderNumber'
      ? query.sortBy(orderBy)
      : query.reverse().sortBy(orderBy));

    return pages.map(({ id, title, orderNumber, updatedAt }) => ({
      id,
      title,
      orderNumber,
      updatedAt,
    }));
  }

  async getPageById(id: string): Promise<Page | undefined> {
    return db.pages.get(id);
  }

  async addPage(pageData?: {
    id?: string;
    title?: string;
    content?: string;
    orderNumber?: number;
  }): Promise<string> {
    const newOrderNumber = 1; // New page always gets the lowest order number
    const pageId = pageData?.id || uuidv4();
    const newPage: Page = {
      id: pageId,
      title: pageData?.title || 'Untitled',
      content: pageData?.content || '',
      createdAt: this.now,
      updatedAt: this.now,
      archivedAt: 0,
      orderNumber: pageData?.orderNumber || newOrderNumber,
    };

    const exists = await db.pages.get(pageId);
    if (exists) return pageId;

    await db.pages.add(newPage);
    return pageId;
  }

  async addDefaultPages() {
    const hasPages = await db.pages.count();
    if (hasPages) {
      return;
    }
    for (const p of INITIAL_PAGES) {
      await this.addPage({
        title: p.title,
        content: p.content ? JSON.stringify(p.content) : '',
        orderNumber: p.orderNumber,
      });
    }
  }

  async updatePageOrder(updates: { id: string; orderNumber: number }[]): Promise<void> {
    await Promise.all(
      updates.map(update => db.pages.update(update.id, { orderNumber: update.orderNumber }))
    );
  }

  async updatePage(id: string, patch: Partial<Page>) {
    return db.pages.update(id, { ...patch, updatedAt: this.now });
  }

  async updateIsPublic(id: string, isPublic: boolean) {
    return db.pages.update(id, { isPublic: isPublic, updatedAt: this.now });
  }

  async archivePage(id: string) {
    return db.pages.update(id, { archivedAt: Date.now(), updatedAt: this.now });
  }

  async unarchivePage(id: string) {
    await db.pages.update(id, { archivedAt: 0, updatedAt: this.now });
    const page = await db.pages.get(id);
    return page;
  }

  async deletePage(id: string): Promise<void> {
    await db.pages.delete(id);
  }

  async searchPages(query: string, archived: boolean = false): Promise<PageMetadata[]> {
    const lowercaseQuery = query.toLowerCase();

    let results: Page[] = [];

    if (!archived) {
      results = await db.pages
        .where('archivedAt')
        .equals(0)
        .reverse()
        .filter(
          page =>
            page.title.toLowerCase().includes(lowercaseQuery) ||
            page.content.toLowerCase().includes(lowercaseQuery)
        )
        .sortBy('updatedAt');
    } else {
      results = await db.pages
        .where('archivedAt')
        .notEqual(0)
        .reverse()
        .filter(
          page =>
            page.title.toLowerCase().includes(lowercaseQuery) ||
            page.content.toLowerCase().includes(lowercaseQuery)
        )
        .sortBy('updatedAt');
    }

    return results.map(({ id, title, orderNumber, updatedAt }) => ({
      id,
      title,
      orderNumber,
      updatedAt,
    }));
  }

  async getArchivedPages(): Promise<Page[]> {
    return db.pages.where('archivedAt').notEqual(0).reverse().sortBy('updatedAt');
  }

  async cleanupOldArchivedPages(): Promise<void> {
    const oneWeekAgo = subWeeks(new Date(), 1).getTime();
    await db.pages
      .where('archivedAt')
      .notEqual(0)
      .and(page => !page.archivedAt || page.archivedAt < oneWeekAgo)
      .delete();
  }

  async bulkUpsertPages(pages: Page[]): Promise<void> {
    await db.pages.bulkPut(pages);
  }

  async bulkDeletePages(pages: Page[]): Promise<void> {
    await db.pages.bulkDelete(pages.map(p => p.id));
  }

  async assignTag(pageId: string, tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Update page's tags
    const page = await this.getPageById(pageId);
    if (page) {
      const tags = new Set(page.tags || []);
      tags.add(normalizedTag);
      await this.updatePage(pageId, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async unassignTag(pageId: string, tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Update page's tags
    const page = await this.getPageById(pageId);
    if (page && page.tags) {
      const tags = new Set(page.tags);
      tags.delete(normalizedTag);
      await this.updatePage(pageId, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async editTag(oldName: string, newName: string) {
    const normalizedOld = oldName.trim().toLowerCase();
    const normalizedNew = newName.trim().toLowerCase();

    if (normalizedOld === normalizedNew) return;

    // Update all pages that have this tag
    const pages = await db.pages.where('tags').anyOf([normalizedOld]).toArray();
    for (const page of pages) {
      const tags = new Set(page.tags || []);
      tags.delete(normalizedOld);
      tags.add(normalizedNew);
      await this.updatePage(page.id, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async deleteTag(tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Remove tag from all pages
    const pages = await db.pages.where('tags').anyOf([normalizedTag]).toArray();
    for (const page of pages) {
      const tags = new Set(page.tags || []);
      tags.delete(normalizedTag);
      await this.updatePage(page.id, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async getTags() {
    const pages = await db.pages.toArray();
    const tagSet = new Set(pages.map(p => p.tags || []).flat());
    const orderedTags = [...tagSet].sort((a, b) => a.localeCompare(b));
    return [...orderedTags];
  }
}

const DBService = new LocalDBService();
export default DBService;

import { useCallback } from 'react';
import { CloudOff, LogOut, RefreshCcw, User } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { useSyncContext } from '@/providers/SyncProvider';
import { formatTimeAgo } from '@/lib/utils';
import { Badge } from '../ui/badge';
import { Skeleton } from '../ui/skeleton';

export function SyncStatus() {
  const { user, authLoading, signIn, signOut, lastSyncedAt, handleSync } = useSyncContext();

  const getInitials = useCallback((email: string) => {
    return email
      .split('@')[0]
      .split('.')
      .map(part => part[0])
      .join('')
      .toUpperCase();
  }, []);

  if (authLoading) {
    return (
      <div className="justify-center items-center">
        <Skeleton className="rounded-full bg-muted w-8 h-8" />
      </div>
    );
  }

  if (!user) {
    return (
      <Button variant="ghost" size="sm" className="h-8 w-8 rounded-full p-0" onClick={signIn}>
        <CloudOff className="h-4 w-4 text-muted-foreground" />
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8 w-8 rounded-full p-0">
          <Avatar className="h-8 w-8">
            <AvatarFallback className="font-large font-bold bg-muted">
              {getInitials(user.email as string)}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DropdownMenuLabel className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <User className="h-4 w-4 text-muted-foreground" />
            <span className="text-sm font-normal text-muted-foreground">Signed in as</span>
          </div>
          <span className="text-sm truncate font-normal">{user.email}</span>
        </DropdownMenuLabel>
        <DropdownMenuItem
          className="flex flex-col items-start gap-1 font-normal cursor-pointer py-3"
          onClick={handleSync}
        >
          <div className="flex items-center gap-2">
            <RefreshCcw className="h-3 w-3 text-muted-foreground" />
            <span className="text-sm text-muted-foreground">Sync Now</span>
            <Badge variant="outline" className="text-xs font-light bg-accent rounded-md px-1">
              CTRL S
            </Badge>
          </div>

          <span className="text-sm font-thin">
            {lastSyncedAt ? `Synced ${formatTimeAgo(lastSyncedAt)}` : 'Never synced'}
          </span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-red-700 focus:text-red-700 flex items-center gap-2 cursor-pointer py-3"
          onClick={signOut}
        >
          <LogOut className="h-4 w-4" />
          <span>Sign out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

import { HelpCircle, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';

const About = ({ onClose }: { onClose: () => void }) => {
  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <CardTitle className="text-sm font-medium">
          <div className="flex items-center justify-center space-x-2 text-2xl">
            <HelpCircle className="h-6 w-6" />
            <span className="pb-1">About Andika ✍🏾</span>
          </div>
        </CardTitle>
        <Button onClick={onClose} variant="ghost" className="h-4 w-4 p-0">
          <X className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent className="text-lg mb-4">
        <p className="mb-2">
          <span className="font-bold">Andika</span> means "write" in Swahili.
        </p>

        <p className="mb-2">
          <strong>Andika Online</strong> is a{' '}
          <span className="italic">minimalist note-taking app</span> that helps you capture your
          thoughts on the fly.
        </p>

        <p className="mb-4">
          With{' '}
          <span className="italic font-semibold text-orange-500">blazing-fast local storage</span>,
          your notes are instantly saved and always available, even offline.
        </p>

        <h2 className="text-lg font-semibold mt-8 mb-2">What makes this special?</h2>
        <p className="mb-1">
          <span className="italic text-yellow-500">Notes are saved locally </span> on your browser
          for fast access. No more loading.
        </p>
        <p className="mb-1">
          <span className="italic text-yellow-500">Optional sign-in</span> to backup and sync your
          notes with other devices.
        </p>
        <p className="mb-1">
          <span className="italic text-yellow-500">Notes are encrypted </span> in-transit & at rest.
          100% for your eyes only.
        </p>
        <p className="mb-1">
          <span className="italic text-yellow-500">Backup </span> your notes to your own Google
          Drive account.
        </p>
        <p className="mb-1">
          <span className="italic text-yellow-500">Share notes </span> with others using public
          links.
        </p>

        <h2 className="text-lg font-semibold mt-8 mb-2">Why use this?</h2>
        <ul className="list-none pl-0 mb-4">
          {['Fast', 'Free', 'Simple'].map((item, index) => (
            <li key={index} className="flex items-center mb-1">
              <span className="text-green-500 mr-2">✓</span> {item}
            </li>
          ))}
        </ul>

        <h2 className="text-lg font-semibold pt-4 mb-2">Some use cases</h2>
        <ul className="list-disc pl-5 mb-4">
          {[
            'Capturing those fleeting ideas',
            'Drafting emails responses etc.',
            'Managing random to-do lists',
            'Capturing meeting notes',
            'Drafting blogs',
            'Saving blogs',
          ].map((item, index) => (
            <li key={index} className="mb-1">
              {item}
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default About;

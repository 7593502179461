import { Badge } from '@/components/ui/badge';
import TagSelector from '@/components/ui/TagSelector';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { Filter } from 'lucide-react';

const FilterConfig = () => {
  const { tags, activeFilters, toggleFilter, clearFilters, deleteTag, updateTag } =
    useLocalDBContext();

  const trigger = (
    <div className="h-8 w-8 px-2 flex justify-center items-center hover:bg-accent hover:text-accent-foreground rounded group relative cursor-pointer">
      <Filter className="h-4 w-4" />
      {activeFilters.length > 0 && (
        <Badge
          className="absolute -top-2 -right-2 h-5 w-5 p-0 flex items-center justify-center bg-transparent text-primary-foreground"
          variant="default"
        >
          {activeFilters.length}
        </Badge>
      )}
    </div>
  );

  return (
    <TagSelector
      trigger={trigger}
      tags={tags}
      selectedTags={activeFilters}
      onSelect={toggleFilter}
      onDelete={deleteTag}
      onEdit={updateTag}
      reset={clearFilters}
      emptyMessage="No filters available"
    />
  );
};

export default FilterConfig;

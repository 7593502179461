import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from '@/pages/HomePage';
import DocumentPage from '@/pages/DocumentPage';
import SharedPage from '@/pages/SharedPage';

import Sidebar from '@/components/Sidebar';
import Menu from '@/components/Menu';

import { ProviderWrapper } from './providers';
import PrivacyPolicy from './pages/Privacy';

export default function App() {
  return (
    <ProviderWrapper>
      <Routes>
        {/* Shared route without sidebar/menu */}
        <Route path="/shared/:pageId" element={<SharedPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Routes with sidebar/menu layout */}
        <Route
          path="/"
          element={
            <MainLayout>
              <HomePage />
            </MainLayout>
          }
        />
        <Route
          path="/page/:pageId"
          element={
            <MainLayout>
              <DocumentPage />
            </MainLayout>
          }
        />
      </Routes>
    </ProviderWrapper>
  );
}

const MainLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <main className="flex-1 overflow-y-auto relative overflow-x-hidden">
        <Menu />
        {children}
      </main>
    </div>
  );
};

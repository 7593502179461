import { useState } from 'react';
import { Settings, Trash2, HelpCircle, LucideIcon, MailIcon, ShieldIcon } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useMediaQuery } from 'usehooks-ts';
import { CustomModal } from '@/components/ui/modal';
import TrashBox from './TrashBox';
import LinkButton from './Feedback';
import About from './About';
import AppSettings from './AppSettings';

const SidebarFooter = () => {
  const [settingModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [aboutModalOpen, setAboutModalOpen] = useState<boolean>(false);

  return (
    <div className="mt-auto mb-4 mx-0 px-2">
      <FooterItem
        icon={Settings}
        label="Settings"
        onClick={() => {
          setSettingsModalOpen(true);
        }}
      />
      <Trash />
      <FooterItem
        icon={HelpCircle}
        label="About"
        onClick={() => {
          setAboutModalOpen(true);
        }}
      />
      <LinkButton
        Icon={MailIcon}
        title={'Feedback'}
        link={`mailto:denis@andika.online?subject=${encodeURIComponent('Feedback for Andika')}`}
      />
      <LinkButton
        Icon={ShieldIcon}
        title={'Privacy Policy'}
        link={`${window.origin}/privacy-policy`}
      />

      <CustomModal
        width={600}
        isOpen={settingModalOpen}
        onClose={() => {
          setSettingsModalOpen(false);
        }}
      >
        <AppSettings
          onClose={() => {
            setSettingsModalOpen(false);
          }}
        />
      </CustomModal>
      <CustomModal
        width={800}
        isOpen={aboutModalOpen}
        onClose={() => {
          setAboutModalOpen(false);
        }}
      >
        <About
          onClose={() => {
            setAboutModalOpen(false);
          }}
        />
      </CustomModal>
    </div>
  );
};

const FooterItem = ({
  icon: Icon,
  label,
  onClick,
}: {
  icon: LucideIcon;
  label: string;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center w-full px-2 py-2 text-sm rounded-md transition-colors duration-150 hover:bg-muted cursor-pointer"
    >
      <Icon className="w-4 h-4 ml-1 mr-3 mt-0.25" />
      {label}
    </div>
  );
};

export default SidebarFooter;

const Trash = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <Popover>
      <PopoverTrigger className="w-full">
        <FooterItem icon={Trash2} label="Trash" />
      </PopoverTrigger>
      <PopoverContent side={isMobile ? 'top' : 'right'} className="mx-1 w-72 p-0 z-[999!important]">
        <TrashBox />
      </PopoverContent>
    </Popover>
  );
};

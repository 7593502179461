import { useState, useEffect } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { Button } from './ui/button';

const RefreshAppPrompt = () => {
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);

  useEffect(() => {
    // Check if we're in a browser environment and service workers are supported
    if ('serviceWorker' in navigator) {
      // Register the service worker
      void navigator.serviceWorker.register('/sw.js').then(registration => {
        // Check for updates
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                setShowUpdatePrompt(true);
              }
            });
          }
        });
      });
    }
  }, []);

  const handleUpdate = () => {
    // Reload the page to activate the new service worker
    window.location.reload();
    setShowUpdatePrompt(false);
  };

  const handleDismiss = () => {
    setShowUpdatePrompt(false);
  };

  if (!showUpdatePrompt) return null;

  return (
    <div className="fixed bottom-4 left-4 right-4 md:left-auto md:right-4 z-50 mx-auto md:mx-0 max-w-md">
      <Alert className="bg-card border border-border text-center shadow-lg">
        <div className="flex justify-center gap-2 my-2">
          <AlertCircle className="h-4 w-4 text-green-600" />
          <AlertTitle>Update Available</AlertTitle>
        </div>
        <AlertDescription>
          <p className="mb-2">Some updates were made to the application.</p>
          <div className="flex gap-2 items-center justify-around">
            <Button variant="ghost" onClick={handleUpdate}>
              Update Now
            </Button>
            <Button variant="ghost" onClick={handleDismiss}>
              Later
            </Button>
          </div>
        </AlertDescription>
      </Alert>
    </div>
  );
};

export default RefreshAppPrompt;

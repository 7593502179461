const CHUNK_SIZE = 1024 * 1024; // 1MB chunks
const ENCRYPTED_PREFIX = 'ENC~'; // Marker for new encrypted data

interface EncryptedChunk {
  iv: string;
  data: string;
  chunkIndex: number;
  finalChunk: boolean;
}

// Improved base64 validation
const isValidBase64 = (str: string): boolean => {
  if (!str) return false;
  try {
    // Check for standard base64 pattern
    return /^[A-Za-z0-9+/]+=*$/.test(str.trim());
  } catch {
    return false;
  }
};

// Validate encrypted chunk structure
const isValidEncryptedChunk = (chunk: unknown): chunk is EncryptedChunk => {
  if (!chunk || typeof chunk !== 'object') return false;

  const typedChunk = chunk as EncryptedChunk;
  return (
    typeof typedChunk.iv === 'string' &&
    typeof typedChunk.data === 'string' &&
    typeof typedChunk.chunkIndex === 'number' &&
    typeof typedChunk.finalChunk === 'boolean' &&
    isValidBase64(typedChunk.iv) &&
    isValidBase64(typedChunk.data)
  );
};

// Check if data matches legacy chunked format
const isLegacyChunkedFormat = (data: string): boolean => {
  try {
    const chunks = JSON.parse(data) as unknown[];
    return Array.isArray(chunks) && chunks.length > 0 && chunks.every(isValidEncryptedChunk);
  } catch {
    return false;
  }
};

// Check if the data appears to be encrypted in any supported format
const isEncryptedData = (data: string): boolean => {
  // Check new format
  if (data.startsWith(ENCRYPTED_PREFIX)) {
    return isLegacyChunkedFormat(data.slice(ENCRYPTED_PREFIX.length));
  }

  // Check legacy format
  return isLegacyChunkedFormat(data);
};

const base64ToUint8Array = (base64: string): Uint8Array => {
  try {
    const cleanBase64 = base64.trim();
    const binaryString = atob(cleanBase64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  } catch (error) {
    throw new Error(`Base64 decode failed: ${(error as Error).message}`);
  }
};

const uint8ArrayToBase64 = (buffer: Uint8Array): string => {
  try {
    const binaryArray = Array.from(buffer);
    const binaryString = String.fromCharCode.apply(null, binaryArray);
    return btoa(binaryString);
  } catch (error) {
    // Handle large arrays that exceed the maximum stack size
    if (error instanceof RangeError) {
      let binary = '';
      const chunks = new Array(Math.ceil(buffer.length / 1024));

      // Process the Uint8Array in smaller chunks
      for (let i = 0; i < buffer.length; i += 1024) {
        const chunk = buffer.slice(i, i + 1024);
        chunks[i / 1024] = String.fromCharCode.apply(null, Array.from(chunk));
      }
      binary = chunks.join('');
      return btoa(binary);
    }
    throw new Error(`Base64 encode failed: ${(error as Error).message}`);
  }
};

const getCryptoKey = async (): Promise<CryptoKey> => {
  const rawKey = import.meta.env.VITE_APP_ENCRYPTION_KEY as string;

  if (!rawKey || typeof rawKey !== 'string') {
    throw new Error('Encryption key not found in environment variables');
  }

  try {
    const hashedKey = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(rawKey));

    return await crypto.subtle.importKey(
      'raw',
      hashedKey,
      { name: 'AES-GCM', length: 256 },
      false,
      ['encrypt', 'decrypt']
    );
  } catch (error) {
    throw new Error(`Key generation failed: ${(error as Error).message}`);
  }
};

const encryptChunk = async (
  chunk: Uint8Array,
  key: CryptoKey,
  chunkIndex: number,
  finalChunk: boolean
): Promise<EncryptedChunk> => {
  try {
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encrypted = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv,
        tagLength: 128,
      },
      key,
      chunk
    );

    return {
      iv: uint8ArrayToBase64(iv),
      data: uint8ArrayToBase64(new Uint8Array(encrypted)),
      chunkIndex,
      finalChunk,
    };
  } catch (error) {
    throw new Error(
      `Chunk encryption failed (index ${String(chunkIndex)}): ${(error as Error).message}`
    );
  }
};

export const encryptData = async (data: string): Promise<string> => {
  if (!data) return '';

  try {
    const key = await getCryptoKey();
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);
    const chunks: EncryptedChunk[] = [];

    for (let i = 0; i < encodedData.length; i += CHUNK_SIZE) {
      const chunk = encodedData.slice(i, i + CHUNK_SIZE);
      const isLastChunk = i + CHUNK_SIZE >= encodedData.length;

      const encryptedChunk = await encryptChunk(chunk, key, chunks.length, isLastChunk);
      chunks.push(encryptedChunk);
    }

    return ENCRYPTED_PREFIX + JSON.stringify(chunks);
  } catch (error) {
    throw new Error(`Encryption failed: ${(error as Error).message}`);
  }
};

const decryptChunk = async (chunk: EncryptedChunk, key: CryptoKey): Promise<Uint8Array> => {
  try {
    if (!isValidEncryptedChunk(chunk)) {
      throw new Error('Invalid chunk format');
    }

    const iv = base64ToUint8Array(chunk.iv);
    const data = base64ToUint8Array(chunk.data);

    const decrypted = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv,
        tagLength: 128,
      },
      key,
      data
    );

    return new Uint8Array(decrypted);
  } catch (error) {
    throw new Error(
      `Chunk decryption failed (index ${String(chunk.chunkIndex)}): ${(error as Error).message}`
    );
  }
};

export const decryptData = async (ciphertext: string): Promise<string> => {
  if (!ciphertext) return '';

  // If not in any encrypted format, return as-is
  if (!isEncryptedData(ciphertext)) {
    return ciphertext;
  }

  try {
    // Handle both new and legacy formats
    const encryptedData = ciphertext.startsWith(ENCRYPTED_PREFIX)
      ? ciphertext.slice(ENCRYPTED_PREFIX.length)
      : ciphertext;

    const chunks = JSON.parse(encryptedData) as EncryptedChunk[];

    // Validate chunk order
    const isValidOrder = chunks.every(
      (chunk, index) =>
        chunk.chunkIndex === index && (index === chunks.length - 1) === chunk.finalChunk
    );

    if (!isValidOrder) {
      throw new Error('Invalid chunk order or missing chunks');
    }

    const key = await getCryptoKey();
    const decryptedChunks: Uint8Array[] = [];

    for (const chunk of chunks) {
      const decryptedChunk = await decryptChunk(chunk, key);
      decryptedChunks.push(decryptedChunk);
    }

    const totalLength = decryptedChunks.reduce((acc, chunk) => acc + chunk.length, 0);
    const combinedArray = new Uint8Array(totalLength);
    let offset = 0;
    for (const chunk of decryptedChunks) {
      combinedArray.set(chunk, offset);
      offset += chunk.length;
    }

    return new TextDecoder().decode(combinedArray);
  } catch (error) {
    // If decryption fails for encrypted data, throw the error
    if (isEncryptedData(ciphertext)) {
      throw new Error(`Decryption failed: ${(error as Error).message}`);
    }
    // For any other parsing error, return the original text
    return ciphertext;
  }
};

import { ElementRef, ReactNode, useEffect, useRef, useState } from 'react';
import { ChevronsLeft, MenuIcon } from 'lucide-react';
import { useMediaQuery } from 'usehooks-ts';
import { cn } from '@/lib/utils';
import { useLocation } from 'react-router-dom';

const SidebarWrapper = ({ children }: { children: ReactNode }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const location = useLocation();

  const sidebarRef = useRef<ElementRef<'aside'>>(null);
  const navbarRef = useRef<ElementRef<'div'>>(null);
  const [isResetting, setIsResetting] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  useEffect(() => {
    if (isMobile) {
      collapse();
    } else {
      resetWidth();
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile || isCollapsed) return;
    const timeout = setTimeout(() => {
      collapse();
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [isMobile, location]);

  useEffect(() => {
    if (isMobile) {
      collapse();
    }
  }, [isMobile]);

  const resetWidth = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(false);
      setIsResetting(true);

      sidebarRef.current.style.width = isMobile ? '100%' : '240px';
      navbarRef.current.style.removeProperty('width');
      navbarRef.current.style.setProperty('width', isMobile ? '0' : 'calc(100%-240px)');
      navbarRef.current.style.setProperty('left', isMobile ? '50%' : '240px');
      setTimeout(() => {
        setIsResetting(false);
      }, 500);
    }
  };

  const collapse = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(true);
      setIsResetting(true);

      sidebarRef.current.style.width = '0';
      navbarRef.current.style.setProperty('width', '50%');
      navbarRef.current.style.setProperty('left', '0');
      setTimeout(() => {
        setIsResetting(false);
      }, 500);
    }
  };

  return (
    <>
      <aside
        ref={sidebarRef}
        className={cn(
          'group/sidebar relative z-[100] flex h-full w-60 flex-col overflow-y-auto bg-secondary border border-l border-border',
          isResetting && 'transition-all duration-500 ease-in-out',
          isMobile && 'w-0'
        )}
      >
        <div
          onClick={collapse}
          role="button"
          className={cn(
            'absolute right-2 top-3 h-6 w-6 mt-2 rounded-sm text-muted-foreground opacity-0 transition hover:bg-muted group-hover/sidebar:opacity-100 ',
            isMobile && 'opacity-100'
          )}
        >
          <ChevronsLeft className="h-6 w-6" />
        </div>
        {children}
      </aside>
      <div
        ref={navbarRef}
        className={cn(
          'absolute left-60 top-0 z-[100]',
          isResetting && 'transition-all duration-500 ease-in-out',
          isMobile && 'left-0 '
        )}
      >
        <nav className={cn(' bg-transparent mt-2 px-3 py-2', !isCollapsed && 'p-0')}>
          {isCollapsed && (
            <MenuIcon
              onClick={resetWidth}
              role="button"
              className="h-6 w-6 text-muted-foreground"
            />
          )}
        </nav>
      </div>
    </>
  );
};

export default SidebarWrapper;

import PageList from './PageList';
import SidebarWrapper from './SidebarWrapper';
import Logo from '../Logo';
import SidebarHeader from './SidebarHeader';
import SearchButton from './Search';
import SidebarFooter from './SidebarFooter';

const Sidebar = () => {
  return (
    <SidebarWrapper>
      <Logo />
      <SearchButton />
      <div className="mt-1 px-2 flex-grow">
        <SidebarHeader />
        <PageList />
      </div>
      <SidebarFooter />
    </SidebarWrapper>
  );
};

export default Sidebar;

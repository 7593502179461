import { useMemo, useRef, useState } from 'react';
import { Check, Pencil, Trash2, X } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';

interface TagSelectorProps {
  trigger: React.ReactNode;
  tags: string[];
  selectedTags: string[];
  onSelect: (tag: string) => void;
  onDelete?: (tag: string) => Promise<void>;
  onEdit?: (oldTag: string, newTag: string) => Promise<void>;
  reset?: () => void;
  allowNewTags?: boolean;
  emptyMessage?: string;
  triggerClassName?: string;
}

const TagSelector = ({
  trigger,
  tags,
  selectedTags,
  onSelect,
  onDelete,
  onEdit,
  reset,
  allowNewTags = false,
  emptyMessage = 'No tags available',
  triggerClassName = '',
}: TagSelectorProps) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const tagOptions = useMemo(() => {
    const selectedSet = new Set(selectedTags);
    return tags
      .map(tag => ({
        value: tag,
        selected: selectedSet.has(tag),
      }))
      .sort((a, b) => {
        // Sort selected items first, then alphabetically
        if (a.selected !== b.selected) return b.selected ? 1 : -1;
        return a.value.localeCompare(b.value);
      });
  }, [tags, selectedTags]);

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && allowNewTags && inputValue.trim()) {
      e.preventDefault();
      onSelect(inputValue.trim());
      setInputValue('');
      setOpen(false);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className={cn(triggerClassName, 'outline-none')}>
        {trigger}
      </PopoverTrigger>
      <PopoverContent className="w-64 p-0 z-[1000] shadow-lg border-border border-2" align="start">
        <Command>
          <CommandInput
            placeholder={allowNewTags ? 'Search or add new tag...' : 'Search tags...'}
            value={inputValue}
            onValueChange={setInputValue}
            onKeyDown={handleInputKeyDown}
          />
          <CommandList>
            <CommandEmpty>{emptyMessage}</CommandEmpty>
            <CommandGroup className="max-h-48 overflow-y-auto">
              {tagOptions.map(tag => (
                <TagItem
                  key={tag.value}
                  tag={tag.value}
                  isSelected={tag.selected}
                  onSelect={() => {
                    onSelect(tag.value);
                  }}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))}
            </CommandGroup>
            {selectedTags.length > 0 && (
              <div className="w-full flex justify-between p-2">
                <div className="text-xs text-muted-foreground border-t">
                  {selectedTags.length} {selectedTags.length === 1 ? 'tag' : 'tags'} selected
                </div>
                {reset && (
                  <button
                    onClick={reset}
                    className="text-xs text-muted-foreground hover:text-foreground flex items-center gap-1"
                  >
                    Clear all
                    <X className="h-3 w-3" />
                  </button>
                )}
              </div>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

interface TagItemProps {
  tag: string;
  isSelected: boolean;
  onSelect: () => void;
  onEdit?: (oldTag: string, newTag: string) => Promise<void>;
  onDelete?: (tag: string) => Promise<void>;
}

const TagItem = ({ tag, isSelected, onSelect, onEdit, onDelete }: TagItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(tag);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    const trimmedValue = editValue.trim();
    if (trimmedValue && trimmedValue !== tag && onEdit) {
      await onEdit(tag, trimmedValue);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      void handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditValue(tag);
    }
  };

  if (isEditing) {
    return (
      <CommandItem
        className="flex items-center gap-2"
        onClick={e => {
          e.preventDefault();
        }}
      >
        <input
          ref={inputRef}
          value={editValue}
          onChange={e => {
            setEditValue(e.target.value);
          }}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          className="flex-1 px-2 py-1 text-sm bg-background border rounded focus:outline-none focus:ring-1 focus:ring-ring"
          autoFocus
        />
      </CommandItem>
    );
  }

  return (
    <CommandItem
      className={cn(
        'flex items-center justify-between group rounded-none cursor-pointer',
        isSelected ? 'bg-accent' : ''
      )}
      onSelect={onSelect}
    >
      <div className="flex items-center gap-2 flex-1">
        <span className="truncate">{tag}</span>
        {isSelected && <Check className="h-4 w-4 flex-shrink-0" />}
      </div>

      {(onEdit || onDelete) && (
        <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
          {onEdit && (
            <button
              onClick={e => {
                e.stopPropagation();
                setIsEditing(true);
              }}
              className="p-1 hover:bg-accent rounded-sm"
            >
              <Pencil className="h-3 w-3" />
            </button>
          )}
          {onDelete && (
            <button
              onClick={e => {
                e.stopPropagation();
                void onDelete(tag);
              }}
              className="p-1 hover:bg-accent rounded-sm"
            >
              <Trash2 className="h-3 w-3" />
            </button>
          )}
        </div>
      )}
    </CommandItem>
  );
};

export default TagSelector;

import { ThemeOption } from '@/types/theme.types';
import { Block, PartialBlock } from '@blocknote/core';
import { darkDefaultTheme, lightDefaultTheme } from '@blocknote/mantine';

export function getBlockNotesStyles(theme: ThemeOption) {
  switch (theme) {
    case 'dark':
      return {
        ...darkDefaultTheme,
        colors: {
          ...darkDefaultTheme.colors,
          editor: {
            text: 'hsl(0 0% 88%;)',
            background: 'hsl(0 0% 15%;)',
          },
          tooltip: {
            text: 'hsl(35 30% 95%)',
            background: 'hsl(0 0% 88%;)',
          },
          hovered: {
            text: 'hsl(35 30% 95%)',
            background: 'hsl(0 0% 15%;)',
          },
          selected: {
            text: 'hsl(35 30% 95%)',
            background: 'hsl(0 0% 15%;)',
          },
          disabled: {
            text: 'hsl(25 30% 30%)',
            background: 'hsl(30 25% 75%)',
          },
        },
      };
    case 'blue':
      return {
        ...darkDefaultTheme,
        colors: {
          ...darkDefaultTheme.colors,
          editor: {
            text: 'hsl(200 30% 96%)',
            background: 'hsl(210 30% 15%)',
          },
          menu: {
            text: 'hsl(200 30% 96%)',
            background: 'hsl(197 47% 18%)',
          },
          tooltip: {
            text: 'hsl(200 30% 96%)',
            background: 'hsl(197 47% 18%)',
          },
          hovered: {
            text: 'hsl(200 30% 96%)',
            background: 'hsl(199 69% 51%)',
          },
          selected: {
            text: 'hsl(200 30% 96%)',
            background: 'hsl(199 69% 51%)',
          },
          disabled: {
            text: 'hsl(200 20% 80%)',
            background: 'hsl(197 47% 18%)',
          },
        },
      };
    case 'hue':
      return {
        ...lightDefaultTheme,
        colors: {
          ...lightDefaultTheme.colors,
          editor: {
            text: 'hsl(60 4.167% 5%)',
            background: 'hsl(40, 56%, 88.5%)',
          },
          tooltip: {
            text: 'hsl(60 4.167% 5%)',
            background: 'hsl(40, 56%, 88.5%)',
          },
          hovered: {
            text: 'hsl(60 4.167% 5%)',
            background: 'hsl(40, 56%, 88.5%)',
          },
          selected: {
            text: 'hsl(60 4.167% 5%)',
            background: 'hsl(40, 56%, 88.5%)',
          },
          disabled: {
            text: 'hsl(0 0% 45.1%)',
            background: 'hsl(40,30.6%,80.8%)',
          },
        },
      };
    default:
      return lightDefaultTheme;
  }
}

export function getBlockNotesContent(content: string | undefined) {
  return content
    ? ((tryParseJSON(content) || [{ type: 'paragraph', content: content }]) as Block[])
    : undefined;
}

function tryParseJSON(jsonString: string): PartialBlock[] | null {
  try {
    const result = JSON.parse(jsonString) as PartialBlock[];
    return Array.isArray(result) ? result : null;
  } catch (e) {
    console.error('Parsing Error: ', e);
    return null;
  }
}

export function parseBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to convert image to base64'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

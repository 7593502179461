import { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { db } from '@/services/database/database';
import { PageMetadata, Page, OrderOption } from '@/types/page.types';
import { isEqual } from 'lodash-es';
import dbService from '@/services/database/LocalDBService';

const ORDER_BY_KEY = 'order-by';

interface LocalDBContextType {
  pagesMetadata: PageMetadata[];
  currentPage: Page | null;
  orderedBy: OrderOption;
  archivedPages: PageMetadata[];
  loading: boolean;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  editable: boolean;
  setEditable: (editable: boolean) => void;
  loadPages: (order?: OrderOption) => Promise<void>;
  setPagesMetadata: (metadata: PageMetadata[]) => void;
  refreshCurrentPage: () => Promise<void>;
  setCurrentPageId: (id?: string) => Promise<void>;
  updateOrderBy: (orderBy: OrderOption) => void;
  addPage: (pageData?: {
    pageId?: string;
    pageTitle?: string;
    content?: string;
  }) => Promise<string | undefined>;
  updatePageTitle: (id: string, title: string) => void;
  updateCurrentPageContent: (content: string) => void;
  deletePage: (id: string) => Promise<void>;
  archivePage: (id: string) => Promise<void>;
  unarchivePage: (id: string) => Promise<void>;
  getPages: () => Promise<Page[]>;
  searchPages: (query: string) => Promise<PageMetadata[]>;
  getArchivedPages: () => Promise<void>;
  searchArchivedPages: (query: string) => Promise<void>;
  reorderPages: (pageIds: string[]) => Promise<void>;
  toggleIsPublic: (id: string, isPublic: boolean) => Promise<void>;
  syncRemoteWithLocal: (
    notesToAdd: Page[],
    notesToUpdate: Page[],
    notesToArchive: Page[]
  ) => Promise<void>;

  tags: string[];
  refreshTags: () => Promise<void>;
  assignTag: (tag: string) => Promise<void>;
  unassignTag: (tag: string) => Promise<void>;
  updateTag: (oldTag: string, newTag: string) => Promise<void>;
  deleteTag: (tag: string) => Promise<void>;

  activeFilters: string[];
  toggleFilter: (tag: string) => void;
  clearFilters: () => void;
}

const LocalDBContext = createContext<LocalDBContextType | undefined>(undefined);

export const LocalDBProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const cachedOrderBy = (localStorage.getItem(ORDER_BY_KEY) || 'updatedAt') as OrderOption;

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [pagesMetadata, setPagesMetadata] = useState<PageMetadata[]>([]);
  const [orderedBy, setOrderedBy] = useState<OrderOption>(cachedOrderBy);
  const [currentPage, setCurrentPage] = useState<Page | null>(null);
  const [archivedPages, setArchivedPages] = useState<Page[]>([]);
  const [editable, setCanEdit] = useState<boolean>(true);

  const [tags, setTags] = useState<string[]>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  useEffect(() => {
    const orderBy = (localStorage.getItem(ORDER_BY_KEY) || 'updatedAt') as OrderOption;
    setOrderedBy(orderBy);
    void refreshTags();
  }, []);

  const updateOrderBy = useCallback((orderOption: OrderOption) => {
    setOrderedBy(orderOption);
    localStorage.setItem(ORDER_BY_KEY, orderOption);
  }, []);

  const setEditable = useCallback((canEdit: boolean) => {
    setCanEdit(canEdit);
    localStorage.setItem('editable', String(canEdit));
  }, []);

  const loadPages = useCallback(
    async (order?: OrderOption) => {
      const defaultOrder = order || cachedOrderBy;
      const pageMetadata = await dbService.getPagesMetadata(defaultOrder, activeFilters);
      setPagesMetadata(pageMetadata);
    },
    [cachedOrderBy, activeFilters]
  );

  const reorderPages = useCallback(async (pageIds: string[]) => {
    updateOrderBy('orderNumber');

    const pageOrders = pageIds.map((id, index) => ({ id, orderNumber: index }));
    await dbService.updatePageOrder(pageOrders);
  }, []);

  const addPage = useCallback(
    async (pageData?: { id?: string; pageTitle?: string; content?: string }) => {
      try {
        const pageId = await dbService.addPage(pageData);
        if (orderedBy === 'orderNumber') {
          const orderUpdates = pagesMetadata.map(p => ({
            id: p.id,
            orderNumber: p.orderNumber + 1,
          }));
          void dbService.updatePageOrder(orderUpdates).then(() => loadPages());
        } else {
          void loadPages();
        }
        return pageId;
      } catch {
        console.warn('Ignored adding...');
      }
    },
    [dbService, loadPages, pagesMetadata.length]
  );

  const updatePageTitle = useCallback(
    (id: string, title: string) => {
      if (!editable) return;

      void dbService.updatePage(id, { title });
      setIsDirty(true);
      setPagesMetadata(pagesMetadata.map(p => (p.id === id ? { ...p, title } : p)));
      if (currentPage && currentPage.id === id) {
        setCurrentPage({ ...currentPage, title, updatedAt: Date.now() });
      }
    },
    [editable, dbService, pagesMetadata.length, currentPage?.id]
  );

  const updateCurrentPageContent = useCallback(
    (content: string) => {
      if (!currentPage || !editable) return;

      const isSame = isEqual(content, currentPage.content);
      if (isSame) return;

      const newCurrentPage = { ...currentPage, content, updatedAt: Date.now() };
      setCurrentPage(newCurrentPage);
      void dbService.updatePage(currentPage.id, newCurrentPage);

      if (
        // this makes sure updatedAt gets reflected on pageList and unsaved changes get displayed
        (pagesMetadata[0].id !== currentPage.id || isDirty) &&
        !currentPage.archivedAt
      ) {
        void loadPages();
      }

      setIsDirty(true);
    },
    [currentPage, editable, pagesMetadata, isDirty, orderedBy, dbService, loadPages]
  );

  const toggleIsPublic = useCallback(
    async (id: string, isPublic: boolean) => {
      if (!currentPage) return;
      setCurrentPage({ ...currentPage, isPublic });
      await dbService.updateIsPublic(id, isPublic);
      await loadPages();
      setIsDirty(true);
    },
    [currentPage?.id, dbService, loadPages]
  );

  const archivePage = useCallback(
    async (id: string) => {
      await dbService.archivePage(id);
      await loadPages();
      setIsDirty(true);
    },
    [dbService, loadPages]
  );

  const unarchivePage = useCallback(
    async (id: string) => {
      await dbService.unarchivePage(id);
      const page = await dbService.getPageById(id);
      if (!page) return;
      if (currentPage?.id === id) {
        setCurrentPage({ ...currentPage, archivedAt: 0 });
      }
      setArchivedPages(archivedPages.filter(p => p.id !== id));
      await loadPages();
      setIsDirty(true);
    },
    [archivedPages, currentPage, dbService, loadPages]
  );

  const deletePage = useCallback(
    async (id: string) => {
      await dbService.deletePage(id);
      setArchivedPages(archivedPages.filter(p => p.id !== id));
    },
    [archivedPages, dbService]
  );

  const setCurrentPageId = useCallback(async (id?: string) => {
    setIsLoading(true);
    const page = id && (await dbService.getPageById(id));
    if (page) {
      setCurrentPage({ ...page });
      setIsLoading(false);
      return;
    }
    const allPages = await dbService.getAllPages(orderedBy);
    if (allPages.length) {
      const page = allPages[0];
      setCurrentPage(page);
      setIsLoading(false);
      return;
    }
    await dbService.addDefaultPages();
    const pages = await dbService.getAllPages(orderedBy);
    if (pages.length) {
      await loadPages('orderNumber');
      const page = allPages[0];
      setCurrentPage(page);
    }
    setIsLoading(false);
  }, []);

  const searchPages = useCallback(
    async (query: string): Promise<PageMetadata[]> => {
      return dbService.searchPages(query);
    },
    [dbService]
  );

  const searchArchivedPages = useCallback(
    async (query: string) => {
      const archived = true;
      const searchResults = await dbService.searchPages(query, archived);
      setArchivedPages(searchResults as Page[]);
    },
    [dbService]
  );

  const getArchivedPages = useCallback(async () => {
    const results = await db.pages.where('archivedAt').notEqual(0).reverse().sortBy('updatedAt');

    setArchivedPages(results);
  }, []);

  const getPages = useCallback(async () => {
    await dbService.cleanupOldArchivedPages();
    const results = db.pages.reverse().sortBy('updatedAt');
    return results as Promise<Page[]>;
  }, [dbService]);

  const syncRemoteWithLocal = useCallback(
    async (notesToAdd: Page[], notesToUpdate: Page[], toDeleteFromLocal: Page[]) => {
      // Perform batch updates
      if (notesToUpdate.length > 0) {
        await dbService.bulkUpsertPages(notesToUpdate);

        // Ensure current page is refreshed
        const updatedCurrentPage =
          currentPage?.id && notesToUpdate.find(p => p.id === currentPage.id);
        if (updatedCurrentPage) {
          setCurrentPage(updatedCurrentPage);
        }
      }

      // Add new pages
      if (notesToAdd.length > 0) {
        await dbService.bulkUpsertPages(notesToAdd);
      }

      // Archive deleted ones
      if (toDeleteFromLocal.length) {
        const archived = toDeleteFromLocal.map(p => ({
          ...p,
          archivedAt: p.archivedAt || Date.now(),
          updatedAt: Date.now(),
        }));
        await dbService.bulkUpsertPages(archived);
      }
      await loadPages();
    },
    [currentPage?.id, dbService, loadPages]
  );

  const refreshCurrentPage = useCallback(async () => {
    const pathParts = window.location.pathname.split('/');
    const pageId = pathParts.pop();
    if (pageId) {
      const page = await dbService.getPageById(pageId);
      if (!page) return;
      setCurrentPage({ ...page });
    } else {
      const allPages = await dbService.getAllPages(orderedBy);
      if (allPages.length) {
        setCurrentPage(allPages[0]);
      }
    }
  }, [orderedBy]);

  const refreshTags = useCallback(async () => {
    const fetchedTags = await dbService.getTags();
    setTags(fetchedTags);
  }, []);

  const handleAddTag = useCallback(
    async (tag: string) => {
      if (!currentPage?.id) return;
      await dbService.assignTag(currentPage.id, tag);
      await refreshTags();
      await refreshCurrentPage();
    },
    [currentPage?.id]
  );

  const handleRemoveTag = useCallback(
    async (tag: string) => {
      if (!currentPage?.id) return;
      await dbService.unassignTag(currentPage.id, tag);
      await refreshTags();
      await refreshCurrentPage();
    },
    [currentPage?.id]
  );

  const handleUpdateTag = useCallback(async (oldTag: string, newTag: string) => {
    await dbService.editTag(oldTag, newTag);
    await refreshTags();
    await refreshCurrentPage();
  }, []);

  const handleDeleteTag = useCallback(async (tag: string) => {
    await dbService.deleteTag(tag);
    await refreshTags();
    await refreshCurrentPage();
  }, []);

  const toggleFilter = useCallback(
    (tag: string) => {
      setActiveFilters(filters =>
        filters.includes(tag) ? filters.filter(f => f !== tag) : [...filters, tag]
      );
    },
    [loadPages]
  );

  const clearFilters = useCallback(() => {
    setActiveFilters([]);
  }, [loadPages]);

  useEffect(() => {
    void loadPages();
  }, [activeFilters, orderedBy]);

  return (
    <LocalDBContext.Provider
      value={{
        pagesMetadata,
        currentPage,
        orderedBy,
        archivedPages,
        loading,

        editable,
        setEditable,

        isDirty,
        setIsDirty,

        tags,
        refreshTags,
        assignTag: handleAddTag,
        unassignTag: handleRemoveTag,
        updateTag: handleUpdateTag,
        deleteTag: handleDeleteTag,

        activeFilters,
        toggleFilter,
        clearFilters,

        setCurrentPageId,
        refreshCurrentPage,

        loadPages,
        setPagesMetadata,
        updateOrderBy,
        addPage,
        deletePage,
        updatePageTitle,
        updateCurrentPageContent,
        archivePage,
        unarchivePage,
        getPages,
        syncRemoteWithLocal,
        searchPages,
        getArchivedPages,
        searchArchivedPages,
        reorderPages,
        toggleIsPublic,
      }}
    >
      {children}
    </LocalDBContext.Provider>
  );
};

export const useLocalDBContext = () => {
  const context = useContext(LocalDBContext);
  if (context === undefined) {
    throw new Error('useLocalDBContext must be used within a LocalDBProvider');
  }
  return context;
};

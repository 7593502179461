import { ReactNode, useState } from 'react';
import { Shield, Cloud, Database, ChevronDown, UserCog, LucideIcon } from 'lucide-react';

const AccordionItem = ({
  title,
  children,
  icon: Icon,
}: {
  title: string;
  children: ReactNode;
  icon: LucideIcon;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-slate-200">
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="w-full px-4 py-4 flex items-center justify-between hover:bg-slate-50 transition-colors"
      >
        <div className="flex items-center gap-2">
          <Icon className="w-5 h-5 text-slate-800" />
          <span className="font-medium text-slate-800 text-left">{title}</span>
        </div>
        <ChevronDown
          className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
        />
      </button>
      <div
        className={`overflow-hidden transition-all duration-200 ease-in-out ${isOpen ? 'max-h-96 pb-4' : 'max-h-0'}`}
      >
        <div className="px-4 text-slate-800">{children}</div>
      </div>
    </div>
  );
};

const PrivacyPolicy = () => {
  const lastUpdated = 'November 1, 2024';

  return (
    <div className="w-full h-screen overflow-y-auto bg-background">
      <div className="p-4 md:p-8">
        <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-sm border border-slate-200">
          {/* Header */}
          <div className="p-6 md:p-8 border-b border-slate-200 space-y-6">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl md:text-3xl font-bold text-slate-800">Privacy Policy</h1>
              <Shield className="w-8 h-8 text-slate-800" />
            </div>

            <div className="bg-slate-50 border border-blue-100 rounded-lg p-4 text-sm text-slate-700">
              Last Updated: {lastUpdated}
            </div>
          </div>

          {/* Content */}
          <div className="relative">
            <div
              className="pb-16"
              style={{
                scrollbarWidth: 'auto',
                msOverflowStyle: 'auto',
              }}
            >
              <div className="p-6 md:p-8 space-y-8">
                {/* Introduction */}
                <section>
                  <h2 className="text-xl font-semibold mb-4 text-slate-800">1. Introduction</h2>
                  <p className="text-slate-800 leading-relaxed">
                    Welcome to Andika Online ("we," "our," or "us"). We are committed to protecting
                    your privacy and ensuring the security of your personal information. This
                    Privacy Policy explains how we collect, use, store, and protect your data when
                    you use our note-taking application.
                  </p>
                </section>

                {/* Data Storage Methods */}
                <section>
                  <h2 className="text-xl font-semibold mb-4 text-slate-800">
                    2. Data Storage Methods
                  </h2>
                  <div className="rounded-lg border border-slate-200 divide-y divide-slate-200">
                    <AccordionItem title="Local Storage" icon={Database}>
                      <ul className="list-disc pl-6 space-y-2 py-2">
                        <li>Your data is primarily stored locally in your browser's storage</li>
                        <li>
                          This data remains on your device and is not transmitted to our servers
                          unless you opt into cloud sync
                        </li>
                        <li>
                          Local data persists until you clear your browser data or manually delete
                          it
                        </li>
                        <li>We cannot access your locally stored data</li>
                      </ul>
                    </AccordionItem>

                    <AccordionItem title="Cloud Storage & Sync (Optional)" icon={Cloud}>
                      <ul className="list-disc pl-6 space-y-2 py-2">
                        <li>
                          Cloud sync is an opt-in feature that requires explicit user activation
                          through signin-up.
                        </li>
                        <li>
                          After signing up, your data is:
                          <ul className="list-circle pl-6 mt-2 space-y-1">
                            <li>Encrypted before transmission</li>
                            <li>Stored on our secure servers with end-to-end encryption</li>
                            <li>Synced across your authorized devices</li>
                          </ul>
                        </li>
                        <li>
                          You maintain control over your cloud-stored data and can delete it at any
                          time
                        </li>
                      </ul>
                    </AccordionItem>

                    <AccordionItem title="Google Drive Backup (Optional)" icon={UserCog}>
                      <ul className="list-disc pl-6 space-y-2 py-2">
                        <li>
                          You may choose to backup your notes to your personal Google Drive account
                        </li>
                        <li>This integration requires explicit authorization from you</li>
                        <li>We use Google's OAuth 2.0 for secure authentication</li>
                        <li>
                          We can only access the specific folder created for your Andika backups
                        </li>
                        <li>We cannot access any other files in your Google Drive</li>
                        <li>You can revoke our access to your Google Drive at any time</li>
                      </ul>
                    </AccordionItem>
                  </div>
                </section>

                {/* Contact Information */}
                <section className="border-t border-slate-200 pt-8">
                  <h2 className="text-xl font-semibold mb-4 text-slate-800">Contact Information</h2>
                  <div className="bg-slate-50 p-6 rounded-lg text-slate-800">
                    <p className="mb-4">For privacy-related questions or concerns:</p>
                    <ul className="space-y-2">
                      <li>
                        <span className="text-slate-800 font-medium">Email:</span>{' '}
                        <a
                          href="mailto:denis@andika.online"
                          className="text-blue-600 hover:underline"
                        >
                          denis@andika.online
                        </a>
                      </li>
                      <li>
                        <span className="text-slate-800 font-medium">Website:</span>{' '}
                        <a href={window.origin} className="text-blue-600 hover:underline">
                          https://andika.online
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

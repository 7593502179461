import { LucideIcon } from 'lucide-react';

const LinkButton = ({ title, link, Icon }: { title: string; link: string; Icon: LucideIcon }) => {
  return (
    <a
      href={link}
      target="_blank"
      className="flex items-center w-full px-2 py-2 text-sm rounded-md transition-colors duration-150 hover:bg-muted cursor-pointer"
    >
      <Icon className="w-4 h-4 ml-1 mr-3" />
      {title}
    </a>
  );
};

export default LinkButton;
